<template>
 <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
         <b-card
           style="max-width: 40rem;"
           class="mb-2"
         >
           <b-card-text>
            <b-form-group id="fieldset-1" label="E-mail" label-for="input-1">
              <b-form-input id="input-1" v-model="email" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Müşteri" label-for="input-1">
              <b-form-input id="input-1" v-model="musteri" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Adres" label-for="input-1">
              <b-form-input id="input-1" v-model="adres" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Telefon" label-for="input-1">
              <b-form-input id="input-1" v-model="telefon" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Cihaz Adeti" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="cihazadeti" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Kullanici Adeti" label-for="input-1">
              <b-form-input id="input-1" v-model="kullanicisiniri" trim></b-form-input>
            </b-form-group>
               <b-form-group id="fieldset-1" label="Distribütör" label-for="input-1">
                    <b-form-select v-model="dist" :options="distoptions"></b-form-select>
               </b-form-group>
               <b-form-group id="fieldset-1" label="Bayi" label-for="input-1">
                   <b-form-input id="input-1" v-model="bayi" trim></b-form-input>
               </b-form-group>
               <b-form-group label="Açıklama">
                   <b-form-textarea
                           id="textarea"
                           v-model="aciklama"
                           placeholder="Açıklama..."
                           rows="3"
                           max-rows="6"
                   ></b-form-textarea>
               </b-form-group>
               <b-form-group label="">

                   <b-form-checkbox
                           id="checkbox-1"
                           v-model="ilksatis"
                           name="checkbox-1"
                           value="1"
                           unchecked-value="0"
                   >
                     İlk Satış
                   </b-form-checkbox>

               </b-form-group>
               <b-form-group label="" v-slot="{ ariaDescribedby }">
                   <b-form-radio-group
                           v-model="demosatis"
                           :options="demosatisoptions"
                           :aria-describedby="ariaDescribedby"
                           name="radio-inline"
                   ></b-form-radio-group>
               </b-form-group>
            <b-form-group id="fieldset-1" label="Lisans Bitiş Tarihi" label-for="input-1">
              <b-form-datepicker id="example-datepicker" v-model="tarih" placeholder="Lisans Bitiş" class="mb-2"></b-form-datepicker>
            </b-form-group>
           </b-card-text>
           <b-button variant="primary" @click="LisansOlustur">Oluştur</b-button>
         </b-card>
      </div>
    </div>
 </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {axiosInstance} from '../../../helpers/axios';
import moment from 'moment';

export default {
 components: {
      Layout,
      PageHeader,

  },
  data() {
      return {
        title: "Müşteri & Lisans Oluştur",
        items: [{
            text: "Anasayfa"
          },
          {
            text: "Lisans & Müşteri işlemleri",
            active: true
          }
        ],
        email:null,
        musteri:null,
        adres:null,
        telefon:null,
        kullanicisiniri:null,
        tarih:null,
        cihazadeti:0,
        dist:"",
        bayi:"",
        demosatisoptions:[
            { text: 'Demo', value: 'Demo' },
            { text: 'Satış', value: 'Satış' },
        ],
        demosatis:"Demo",
        aciklama:"",
        ilksatis:0,
        distoptions:[]
      }
  },
  created(){
    var vm = this;
    vm.dist_list();
  },
  computed:{
    yetkisi()
      {
        return this.$store.getters["auth/rl"];
      },
      dist_state()
      {
        return this.$store.getters["auth/dist"];
      }
  },
  methods: {
   LisansOlustur()
   {
     var vm = this;
     try {

      axiosInstance.post("license/create",{
         email:vm.email,
         lisanstarih:moment(vm.tarih).format("YYYYMMDD"),
         kullanicisiniri:vm.kullanicisiniri,
         firmaadi:vm.musteri,
         adres:vm.adres,
         telefon:vm.telefon,
         dist:vm.dist,
         bayi:vm.bayi,
         demosatis:vm.demosatis,
         aciklama:vm.aciklama,
         ilksatis:vm.ilksatis,

         cihazsiniri:parseInt(vm.cihazadeti)
      }).then((response) => {


        console.log(response);

        
        

        

       if(response.data.error==true)
            {
              vm.$bvToast.toast(response.data.message, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            }else{
              vm.$bvToast.toast("Lisans Oluşturuldu", {
                title: `Bildirim !`,
                variant: "info",
                solid: true
              });
            }

      }).catch((error)=>{
         vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
      })
      
     } catch (error) {
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
     }
   },
   dist_list()
        {
            var vm = this;
            try {

                axiosInstance.post("dist/list").then((response)=>{

                 

                     if(response.data.error==true)
                        {
                        vm.$bvToast.toast(response.data.message, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                        }else{

                            
                           
                            response.data.data.forEach(element => {

                              
                                  vm.distoptions.push({
                                    value:element.id,
                                    text:element.adi,
                                  });
                                  if(vm.yetkisi=="basicadmin")
                                  {
                                    vm.dist = element.id;
                                  }
                                  
                                
                            });
                        }

                }).catch((error)=>{

                    vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                    
                })
                
            } catch (error) {

              

                vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                
            }
  },
  },
}
</script>